.has-swatch-01-background-color, .wp-block-button.is-style-swatch-01 a.wp-block-button__link { background-color: $swatch-01 };
.has-swatch-02-background-color, .wp-block-button.is-style-swatch-02 a.wp-block-button__link { background-color: $swatch-02 };
.has-swatch-03-background-color, .wp-block-button.is-style-swatch-03 a.wp-block-button__link { background-color: $swatch-03 };
.has-swatch-04-background-color, .wp-block-button.is-style-swatch-04 a.wp-block-button__link { background-color: $swatch-04 };
.has-swatch-05-background-color, .wp-block-button.is-style-swatch-05 a.wp-block-button__link { background-color: $swatch-05 };
.has-swatch-06-background-color, .wp-block-button.is-style-swatch-06 a.wp-block-button__link { background-color: $swatch-06 };
.has-swatch-07-background-color, .wp-block-button.is-style-swatch-07 a.wp-block-button__link { background-color: $swatch-07 };
.has-swatch-08-background-color, .wp-block-button.is-style-swatch-08 a.wp-block-button__link { background-color: $swatch-08 };
.has-swatch-09-background-color, .wp-block-button.is-style-swatch-09 a.wp-block-button__link { background-color: $swatch-09 };
.has-swatch-10-background-color, .wp-block-button.is-style-swatch-10 a.wp-block-button__link { background-color: $swatch-10 };
.has-swatch-11-background-color, .wp-block-button.is-style-swatch-11 a.wp-block-button__link { background-color: $swatch-11 };
.has-swatch-12-background-color, .wp-block-button.is-style-swatch-12 a.wp-block-button__link { background-color: $swatch-12 };
.has-swatch-13-background-color, .wp-block-button.is-style-swatch-13 a.wp-block-button__link { background-color: $swatch-13 };
.has-swatch-14-background-color, .wp-block-button.is-style-swatch-14 a.wp-block-button__link { background-color: $swatch-14 };
.has-swatch-15-background-color, .wp-block-button.is-style-swatch-15 a.wp-block-button__link { background-color: $swatch-15 };
.has-swatch-16-background-color, .wp-block-button.is-style-swatch-16 a.wp-block-button__link { background-color: $swatch-16 };
.has-swatch-17-background-color, .wp-block-button.is-style-swatch-17 a.wp-block-button__link { background-color: $swatch-17 };
.has-swatch-18-background-color, .wp-block-button.is-style-swatch-18 a.wp-block-button__link { background-color: $swatch-18 };
.has-swatch-19-background-color, .wp-block-button.is-style-swatch-19 a.wp-block-button__link { background-color: $swatch-19 };


.has-swatch-01-color { color: $swatch-01 };
.has-swatch-02-color { color: $swatch-02 };
.has-swatch-03-color { color: $swatch-03 };
.has-swatch-04-color { color: $swatch-04 };
.has-swatch-05-color { color: $swatch-05 };
.has-swatch-06-color { color: $swatch-06 };
.has-swatch-07-color { color: $swatch-07 };
.has-swatch-08-color { color: $swatch-08 };
.has-swatch-09-color { color: $swatch-09 };
.has-swatch-10-color { color: $swatch-10 };
.has-swatch-11-color { color: $swatch-11 };
.has-swatch-12-color { color: $swatch-12 };
.has-swatch-13-color { color: $swatch-13 };
.has-swatch-14-color { color: $swatch-14 };
.has-swatch-15-color { color: $swatch-15 };
.has-swatch-16-color { color: $swatch-16 };
.has-swatch-17-color { color: $swatch-17 };
.has-swatch-18-color { color: $swatch-18 };
.has-swatch-19-color { color: $swatch-19 };

$colors: (
	'primary': $primary,
	'secondary': $secondary,
	'swatch-01': $swatch-01,
	'swatch-02': $swatch-02,
	'swatch-03': $swatch-03,
	'swatch-04': $swatch-04,
	'swatch-05': $swatch-05,
	'swatch-06': $swatch-06,
	'swatch-07': $swatch-07,
	'swatch-08': $swatch-08,
	'swatch-09': $swatch-09,
	'swatch-10': $swatch-10,
	'swatch-11': $swatch-11,
	'swatch-12': $swatch-12,
	'swatch-13': $swatch-13,
	'swatch-14': $swatch-14,
	'swatch-15': $swatch-15,
	'swatch-16': $swatch-16,
	'swatch-17': $swatch-17,
	'swatch-18': $swatch-18,
	'swatch-19': $swatch-19
);

$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'swatch-01': $swatch-01,
	'swatch-02': $swatch-02,
	'swatch-03': $swatch-03,
	'swatch-04': $swatch-04,
	'swatch-05': $swatch-05,
	'swatch-06': $swatch-06,
	'swatch-07': $swatch-07,
	'swatch-08': $swatch-08,
	'swatch-09': $swatch-09,
	'swatch-10': $swatch-10,
	'swatch-11': $swatch-11,
	'swatch-12': $swatch-12,
	'swatch-13': $swatch-13,
	'swatch-14': $swatch-14,
	'swatch-15': $swatch-15,
	'swatch-16': $swatch-16,
	'swatch-17': $swatch-17,
	'swatch-18': $swatch-18,
	'swatch-19': $swatch-19
);

.btn-swatch-11 {
	color: $white !important;
}