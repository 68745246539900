.wp-block-group.alignfull {
    padding: 0;
}

.split {
    .row {
        min-height: 50vh;
    }

    .wp-block-group .wp-block-group__inner-container {
        @include media-breakpoint-up(md) {
        max-width: 500px;
        }
    }

    figure.position-relative {
        height: 100%;
        margin: 0;
        
        img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 768px) {
            min-height: 500px;
        }
    }

}

.card:not(.vertical) {
    figure.position-relative {
        
        img {
            @include media-breakpoint-up(md) {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

/*
.card {
    a.btn {
        margin: 1rem;
    }
};
*/

.display-posts .card {
    figure {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.team.card {
    img {
        height: auto;
    }
}

.display-posts .card.horizontal {
    figure {
        img {
            @include media-breakpoint-up(md) {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

/*
.display-posts .card {
    flex-direction: row !important;
    position: relative;
    margin-bottom: 2rem;
    border: 1px solid #d5d5d7;
}


.display-posts .card .card-body {
    align-self: center !important;
    flex-direction: column !important;
    display: flex !important;
    width: 100%
}
*/

@media (max-width:575.98px) {
    .display-posts .card .card-body {
        width: auto
    }
}

.wp-block-button__link {
    @extend .btn;
}

.wp-block-quote {
    border-width: 2px;
    @extend .ms-5, .my-5, .h3;
}

.csection {

    ul.display-posts,
    ul.display-posts li:last-child {
        margin-bottom: 0 !important;

    }

    .wp-block-cover {
        min-height: inherit;
        height: 100%;
    }
}