@import 'sidebar';

.navbar-brand {
    padding-top: 1rem;
    padding-bottom: 1rem;

    img {
        height: 100%;
        width: 190px;
    }
}

aside span.h4 {
    display: block;
    overflow: hidden;
    margin-bottom: 1rem
}

aside ul {
    list-style: none;
    margin: 0;
    padding: 0
}

aside ul a {
    display: block;
    border-bottom: 1px solid $swatch-12;
    padding: 10px 0;
    color: #5a5b5d
}

aside ul.pagelist {
    
    li.current_page_item > a,
    li.current_page_parent > a,
    li.current_page_ancestor > a {
        @extend .link-swatch-04;
    }

    li:not(.current_page_ancestor) ul.children {
        display: none;
    }

    li.page_item_has_children.current_page_item > ul.children {
        display: block;
    }

    ul.children {

        li.current_page_item > a,
        li.current_page_item > a:hover,
        li.current_page_ancestor > a {
            @extend .link-swatch-04;
        }

        ul.children {

            li.current_page_item > a,
            li.current_page_item > a:hover,
            li.current_page_ancestor > a {
                @extend .link-swatch-04;
            }
        }
    }

    ul.children a {
        padding: .5rem 0 .5rem 1rem !important;
    }

    ul.children ul.children a {
        padding: .5rem 0 .5rem 2rem !important;
    }

    ul.children ul.children ul.children a {
        padding: .5rem 0 .5rem 3rem !important;
    }
}

#pages-sidebar {

    ul.pagelist {

        @extend .list-group;

        li {
            @extend .list-group-item;
            border: 0;
            padding: 0;

            a {
                font-size: 16px;
                font-weight: 200;
                line-height: 1;
            }
        }
    }
}

#news-sidebar {

    ul {

        @extend .list-group;

        li {
            @extend .list-group-item;
            border: 0;
            padding: 0;

            a {
                font-size: 16px;
                font-weight: 200;
                line-height: 1.2;
            }
        }
    }

    aside {
        margin-bottom: 2rem;
    }
}

.menu-secondary {

    @include media-breakpoint-down(xl) {
        display: none;
    }

    .nav-link {
        font-weight: 300;
        font-size: 14px;
        line-height: 1;

        @include media-breakpoint-up(md) {
            padding-right: 0.6rem !important;
            padding-left: 0.6rem !important;
        }
        
    }

    form.search-form {
        max-width: 130px;
        
        input#search {
            background: $swatch-14;
            color: $white;
            font-size: 1rem;
            padding: .5rem;
            padding-left: 2rem;
        }
        
        &::placeholder {
            color: $white;
        }

        .bi-search::before {
            padding: 12px;
            font-size: 16px;
            color: $white;
        }
        
    }
}

form.search-form {
    position: relative;
    display: block;
    width: 100%;
    

    label {
        display: none;
        font-size: 0;
        margin: 0;
        height: 100%;
    }

    i {
        position: absolute;
        font-style: normal;
        font-weight: 100;
        color: $swatch-14;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        pointer-events: none;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .input-group {
        height: 100%;

        &::after {
            margin-right: 0.3rem;
        }
    }

    .bi-search::before {
        content: "\f52a";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        padding: 18px;
        font-size: 24px;
    }

    input#search {
        background: $swatch-12;
        font-weight: 100;
        
        border: 0 none;
        color: $swatch-14;
        padding: 1rem;
        padding-left: 3rem;

        &::placeholder {
            color: $swatch-14;
            text-indent: .5rem;
        }

        &:focus {
            outline: none;
            border: 0 none;
            box-shadow: none;
        }



        
    }

}

.menu-primary {

    .nav-link:hover,
    .nav-link:focus {
        color: $swatch-04;
    }

    li.active .nav-link,
    li.current-page-ancestor .nav-link,
    li.current-page-ancestor .dropdown-menu .current-menu-item a {
        @extend .link-swatch-09;
    }

    .nav-link {
        font-family: 'vista-slab';
        @extend .link-swatch-02;

        @include media-breakpoint-only(md) {
            font-size: .8rem;
            padding-left: .3rem !important;
            padding-right: .3rem !important;
        }
    }

    .navbar-toggler {
        @extend .link-swatch-16;
        position: absolute;
        right: 1rem;
        background: $swatch-02;
    }

    .navbar-toggler-icon {
        display: contents !important;
        color: $white;
    }
}

footer.footer {

    .menu-item:not(:last-child) .nav-link {
        @include media-breakpoint-up(xl) {
            border-right: 1px solid $swatch-19;
        }
    }

    .nav-link {
        font-weight: 100;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        
        @extend .link-swatch-19;
        @include media-breakpoint-up(xl) {
            padding: 0;
            height: 20px;
            line-height: 1.5;
        }
    }
}

footer.footer,
.wpml-ls-legacy-list-horizontal.wpml-ls-statics-footer {
    border-top: 1px solid $swatch-13;
    padding: 1rem 0;
    @include media-breakpoint-down(xl) {
        padding: 2rem 0;
    }
}

.wpml-ls-legacy-list-horizontal.wpml-ls-statics-footer {
    margin: 0;

    a {
        font-size: .8rem;
        @extend .link-swatch-19
    }
}