// Custom.scss
//@import "gotham";
@import "custom";
@import "colors";


@import "~bootstrap";
@import "type";

/*
// functions first
@import "~bootstrap/scss/functions";
// variable overrides
@import "custom";

// required stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// optional
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/tables";

// utilities
@import "~bootstrap/scss/utilities/api";

// site css

// @import "~bootstrap-icons/font/bootstrap-icons.css";
*/
@import '../../node_modules/swiper/swiper';
@import '../../node_modules/swiper/modules/pagination/pagination.scss';

@import "navigation";
@import "sidebar";

body {
    min-width: 280px;
}

h1, h2, h3, h4, h5, h6,
p.h1, p.h2, p.h3, p.h4, p.h5, p.h6,
span.h1, span.h2, span.h3, span.h4, span.h5, span.h6 {
    font-family: vista-slab;
    font-weight: 600;
}

@import "mega-menu";
@import "blocks";


body.logged-in,
body.logged-in .offcanvas  { 
    padding-top: 46px;
}

@media (min-width: 783px) {
    body.logged-in,
    body.logged-in .offcanvas  { 
        padding-top: 32px;
    }
}

header.header {
    background: $swatch-05;
    margin-bottom: 2rem;
    color: $white;

    .container {
        background-repeat: no-repeat !important;
        background-position: right !important;
        background-size: contain;
    }
}

.fs-0 { font-size: 0; }

.modal {
    top: 50%;
    transform: translateY(-50%);

    .modal-dialog {
        margin: 3rem auto;
    }
}

input[type="submit"] {
    @extend .btn-primary;
}

body.home .display-posts,
body.home .select-posts-block
 {
    .card-body {

        .card-title {
            font-size: $h3-font-size;
            font-weight: 400;
            margin-top: 0;
            color: inherit;
        }

        a {
            color: inherit;
        }
    }
}

.display-posts,
.select-posts-block
 {
    .card-body {

        & + .btn {
            align-self: center;
        }

        .card-title {
            font-size: $h2-font-size;
            font-weight: 400;
            margin-top: 1.5rem;
        }
    }
}

.display-posts.slider {
    figure {
        padding: 1rem;
    }

    .card-title {
        margin-top: 0;
    }
}

.countup {
    @include font-size(110px);
    font-weight: 200 !important;
    line-height: 1 !important;
    white-space: nowrap;
}

.single-post main {
    figure {
        img.wp-post-image {
            width: 100%;
            height: auto;
        }
    }
}

ul.display-posts.hover li::after,
.wp-block-group.hover > div::after { 
    content: '';
    height: 100%; 
    width: 100%;  
    left: 0; 
    top: 0;
    opacity: 0;
    position: absolute; 
    transition: all 0.3s ease 0s; 
    -webkit-transition: all 0.3s ease 0s; 
    z-index: 0;
    background: $primary;
    cursor: pointer;
  }
  
ul.display-posts.hover .card:hover::after,
.wp-block-group.hover > div:hover::after  { opacity: .2; }

@media only screen and (max-width: 760px) {

    .wp-block-cover {
        min-height: 200px !important;
    }

    .home .wp-block-cover {
        min-height: 300px !important;
    }

    .post img {
        max-width: 364px !important;
        max-height: 273px !important;
    }

}