.offcanvas {

    .offcanvas-header {
        padding: 0.5rem 1rem;
    }

    .offcanvas-body {
        overflow-x: hidden;
    }

    #menu-mobile {
        padding: 0;
        

        .card {
            border: 0;
        }

        li.card {
            border: 0;
            padding: .5rem .8rem;
            background: $swatch-12;

            &:hover {
                background: $white;
            }

            a.arrow-down:after {
                content: '\F229';
                font-family: 'bootstrap-icons';
                width: 30px;
                height: 26px;
                position: absolute;
                right: 0;
            }
        }

        li.card[aria-expanded="true"] {
            background: $swatch-02;

            a {
                color: $white;
            }

            a.arrow-down:after {
                content: '\F22D';
                font-family: 'bootstrap-icons';
                width: 30px;
                height: 26px;
                position: absolute;
                right: 0;
            }
        }

        li.card.shown,
        li.card.active {
            background: $swatch-19;
            .btn-toggle {
                color: $white;
                //background-color: $swatch-12;
            }
            a {
                @extend .link-swatch-16
            }
        }

        .card-body {
            padding: 0;
        }


        .btn-toggle {
            display: inline-flex;
            align-items: center;
            padding: 10px;
            font-weight: 600;
            color: $swatch-19;
            background-color: transparent;
            border: 0;
            position:absolute;
            right: 10px;
            height: 30px;
            width: 37px;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: none;
          }
          .btn-toggle:hover,
          .btn-toggle:focus {
            color: $swatch-19;
            //background-color: $swatch-12;
          }
          
          .btn-toggle::before {
            
            line-height: 0;
            content: '\F284';
            font-family: 'bootstrap-icons';
            transition: transform .35s ease;
            // transform-origin: .5em 50%;
          }
          
          .btn-toggle[aria-expanded="true"] {
            color: rgba(0, 0, 0, .85);
          }
          .btn-toggle[aria-expanded="true"]::before {
            transform: rotate(90deg);
          }
          
          .btn-toggle-nav a {
            display: inline-flex;
            padding: .1875rem .5rem;
            margin-top: .125rem;
            margin-left: 1.25rem;
            text-decoration: none;
          }
          .btn-toggle-nav a:hover,
          .btn-toggle-nav a:focus {
            background-color: #d2f4ea;
          }
    }

    form.search-form {
        
        input#search {
            background: $swatch-12;
            color: $swatch-14;
            font-size: 1rem;
            padding-left: 3rem;
        }
        
        &::placeholder {
            color: $swatch-14;
            text-indent: .5px;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:focus,
        input:-internal-autofill-selected {
            transition: background-color 600000s 0s, color 600000s 0s;
        }
        
    }
}