.has-display-1-font-size {
    //font-size:  !important;
    @include font-size(map-get($display-font-sizes, 1));
    line-height: $display-line-height;
}

.has-display-2-font-size {
    @include font-size(map-get($display-font-sizes, 2));
    line-height: $display-line-height;
}

.has-display-3-font-size {
    @include font-size(map-get($display-font-sizes, 3));
    line-height: $display-line-height;
}

.has-h-1-font-size {
    font-size: $h1-font-size !important;
}

.has-h-2-font-size {
    font-size: $h2-font-size !important;
}

.has-h-3-font-size {
    font-size: $h3-font-size !important;
}

.has-h-4-font-size {
    font-size: $h4-font-size !important;
}