.mega-menu-wrapper {
  display: none;
  background: $swatch-12;

  li.widget_text {
    margin-right: 3rem;
    width: 45%;
    float: left;

    p.h3 {
        color: $swatch-04;
        font-size: 3rem;
        margin-bottom: 1rem;
        margin-top: 0;
        line-height: 1;
    }
    a.btn {
      @extend .btn-swatch-04;
    }
}

li:last-child {
     ul {
        float: right;
        width: 50%;
        padding-left: 0;

        li {
            position: relative;
            color: $swatch-09;

            &::before {
                content: '\F285';
                font-family: 'bootstrap-icons';
                position: absolute;
            }

            a {
                color: $swatch-04;
                font-weight: 600;
                padding: 0 2rem;
                line-height: 1 !important;
                padding-bottom: 0 !important;

                &:hover,
                &:focus {
                    color: $swatch-09;
                }
            }
        }
    }
}
}

.mega-menu-wrapper ul{
  list-style-type: none;
}

ul.mega-menu-wrapper li:last-child ul{
  width:100%!important;
}

/**Mega Menu **/
.navbar .container {
    position: relative;
}
.navbar-nav, .navbar .collapse, .navbar-nav li {
  position: static;
}
.menu-item-has-children, .menu-item-has-children .dropdown-menu {
  left: auto;
}
.menu-item-has-children .dropdown-menu {
  width: auto !important;
}

/* Make dropdown visible on hover */
@media (min-width: 767px) {
    .dropdown > .dropdown-menu {
      position: absolute;
      top: 100%;
      left: auto;
      z-index: 1000;
      float: none;
      min-width: 10rem;
      padding: 1rem;
      list-style: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      opacity: 0;
      margin-top: 10px !important;
      display: block;
      visibility:hidden;
      min-width: 150px;
      transition: all  0.2s ease-in-out;
    }
    .show > .dropdown-menu {
      visibility:visible;
      display: block;
      opacity: 1;
      margin-top: 5px !important; 
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      transition: all  0.2s ease-in-out;
    }
      .mega-menu-parent.dropdown .dropdown-menu {
        position: absolute;
        left: 0;
        top: 95px;
        width: 100%;
        margin: 0 !important;
    }
  }
  