$swatch-01: #95979A;
$swatch-02: #5A5B5D;
$swatch-03: #009AD6;
$swatch-04: #004B85;
$swatch-05: #69207E;
$swatch-06: #3B8238;
$swatch-07: #FFDD00;
$swatch-08: #E00371;
$swatch-09: #00203E;
$swatch-10: #999999;
$swatch-11: #487D93;
$swatch-12: #EFEFEF;
$swatch-13: #CCCCCC;
$swatch-14: #5A5B5C;
$swatch-15: #333333;
$swatch-16: #FFFFFF;
$swatch-17: #717274;
$swatch-18: #209CDD;
$swatch-19: #707070;

$white: #ffffff;
$black: #000;
$grey: #CCCCCC;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$primary: $swatch-04;
$secondary: $swatch-05;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

$font-family-sans-serif: Arial;
$font-size-root: 18px;
$font-size-base: 1rem;

$spacer: 1rem;

$body-color: $swatch-19;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.22223;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$display-font-sizes: (
  1: 3.5rem,
  2: 3.125rem,
  3: 2.89rem,
  );
  
$headings-line-height: 1.3;
$headings-margin-bottom: $spacer;
$headings-font-family: vista-slab;
$headings-font-weight: 500;
$headings-line-height: 1.2;

$display-font-weight: 500;
$display-line-height: 1.2;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1860px
);

$container-max-widths: (
  sm: 570px,
  md: 800px,
  lg: 960px,
  xl: 1140px,
  xxl: 1600px
);

$modal-lg: 800px;
$modal-md: 800px;
$modal-sm: 300px;

$modal-transition: transform .5s ease-out;

$enable-caret: false;
$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: true;
$enable-negative-margins: true;

$link-decoration: none;

$input-btn-font-family: $headings-font-family;
$input-font-family: $font-family-sans-serif;
$btn-font-weight: 500;
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1rem;

$navbar-dark-color: $white;        
$navbar-dark-active-color: rgba($white, .75);

